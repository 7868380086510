"use client";

import { DateRangePicker } from "@/components/tremor/datepicker";
import React from "react";
import moment from "moment";
import { setupDatepicker } from "@/utils/utils";

export const DateRangePickerV2 = ({ onChange }) => {
  const [dateRange, setDateRange] = React.useState(setupDatepicker());

  const handleOnChange = (value) => {
    setDateRange(value);

    if (!value.compare) {
      const diffDays = moment
        .duration(
          moment(value.to, "YYYY-MM-DD").diff(moment(value.from, "YYYY-MM-DD"))
        )
        .asDays();

      value.compare = {
        from: moment(value.from)
          .subtract(diffDays + 1, "day")
          .toDate(),
        to: moment(value.from, "YYYY-MM-DD").subtract(1, "day").toDate(),
      };
    }

    const obj = {
      from_start: moment(value.compare.from, "YYYY-MM-DD"),
      from_end: moment(value.compare.to, "YYYY-MM-DD"),
      to_start: moment(value.from, "YYYY-MM-DD"),
      to_end: moment(value.to, "YYYY-MM-DD"),
    };

    onChange(obj);
  };

  const presets = [
    {
      label: "Yesterday",
      dateRange: {
        from: new Date(new Date().setDate(new Date().getDate() - 1)),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(new Date().setDate(new Date().getDate() - 2)),
          to: new Date(new Date().setDate(new Date().getDate() - 2)),
        },
      },
    },
    {
      label: "This week",
      dateRange: {
        from: new Date(moment().startOf("isoWeek")),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(moment().subtract(1, "week").startOf("isoWeek")),
          to: new Date(new Date().setDate(new Date().getDate() - 8)),
        },
      },
    },
    {
      label: "Last week",
      dateRange: {
        from: new Date(moment().subtract(1, "week").startOf("isoWeek")),
        to: new Date(moment().subtract(1, "week").endOf("isoWeek")),
        compare: {
          from: new Date(moment().subtract(2, "week").startOf("isoWeek")),
          to: new Date(moment().subtract(2, "week").endOf("isoWeek")),
        },
      },
    },
    {
      label: "Last 28 days",
      dateRange: {
        from: new Date(new Date().setDate(new Date().getDate() - 28)),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(new Date().setDate(new Date().getDate() - 56)),
          to: new Date(new Date().setDate(new Date().getDate() - 29)),
        },
      },
    },
    {
      label: "Last 30 days",
      dateRange: {
        from: new Date(new Date().setDate(new Date().getDate() - 30)),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(new Date().setDate(new Date().getDate() - 60)),
          to: new Date(new Date().setDate(new Date().getDate() - 31)),
        },
      },
    },
    {
      label: "Month to date",
      dateRange: {
        from: new Date(new Date().setDate(1)),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
          to: new Date(new Date().setDate(1)),
        },
      },
    },
    {
      label: "Year to date",
      dateRange: {
        from: new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1)),
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        compare: {
          from: new Date(
            new Date().setFullYear(new Date().getFullYear() - 1, 0, 1)
          ),
          to: new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1)),
        },
      },
    },
  ];

  return (
    <div className="flex flex-col items-center gap-y-4">
      <DateRangePicker
        presets={presets}
        value={dateRange}
        onChange={handleOnChange}
        className="w-64"
      />
    </div>
  );
};
